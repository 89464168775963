<script setup>
	// OrderHistoryPanel
	// Shows order history in the my account area.
	//import { ref, watch } from 'vue';
	import { useRoute } from 'vue-router';
	// Components ----
	import OrderHistoryTile from '@/site/components/OrderHistoryTile';
	import MockProductListTile from '@/site/components/MockProductListTile';
    import { Search } from '@dd-nucleus/nucleus-vue';

	// Imports ----

	// State ----
	const pageSizeOptions = [12, 24, 36, 48, 60];

    const statusOptions = [
        { id: 'pending', text: 'Pending' },
        { id: 'completed', text: 'Completed' },
        { id: 'canceled', text: 'Canceled' },
	];

    const shippingOptions = [
        { id: 'shipped', text: 'Shipped' },
        { id: 'not shipped', text: 'Not Shipped' },
        { id: 'partially shipped', text: 'Partially Shipped' }
	];

	const orderTypeOptions = [
        { id: 'standard', text: 'Standard Order' },
		{ id: 'conference', text: 'Conference Order' },
        { id: 'multi-ship', text: 'Multi Ship Order' }
	]

	const route = useRoute();

	const search = new Search('orders-for-user', 'tile', "order-newest-to-oldest", route.query['per-pg'] ?? 12);
    search.setFilterValues('include-firstOrder-in-bulkOrders', [''])


</script>

<template>
	<div class="account-panel order-history-panel">
		<h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Order History</span></h1>

		<SearchContainer :search="search" default-view="list" :deep-link="true">
			<div class="filter-bar mb-3">
				<div class="mb-2">
					<TextBoxFilter filter-id="search-term" placeholder="Search orders by no, name, or email" />
				</div>

				<div>
					<DateRangeFilter :useOnlyDate="true" filter-id="order-date-range" />
				</div>
			</div>

			<div class="col-12 mb-3">
				<label class="fw-bold">Order Status</label>
				<div class="row">
					<div class="col-3" v-for="opt of statusOptions" :key="opt.id">
						<AddFilterCheckBox filter-id="order-status" :values="[opt.id]" :label="opt.text" />
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<label class="fw-bold">Shipping Status</label>
				<DropDownListFilter filter-id="shipping-status" :options="shippingOptions" nullOptionText="All" enableNullOption="true" />
			</div>

			<div class="row mb-3">
				<label class="fw-bold">Order Type</label>
				<DropDownListFilter filter-id="az-order-type" :options="orderTypeOptions" nullOptionText="All" enableNullOption="true" />
			</div>

			<div class="d-flex mb-3">
				<div><PageSizeList :options="pageSizeOptions" /></div>
				<div class="flex-fill text-end"><PageSelector /></div>
			</div>

			<SearchGrid>
				<template v-slot:list="order">
					<OrderHistoryTile :order="order" />
				</template>

				<template #empty>
					<div>
						There is no order history currently available for your account. For more help please <router-link class="" to="/help/contact">Contact Us.</router-link>
					</div>
				</template>

				<template #loading-list>
					<MockSearchGrid>
						<MockProductListTile />
					</MockSearchGrid>
				</template>
			</SearchGrid>

			<div class="d-flex">
				<div class="flex-fill text-end"><PageSelector /></div>
			</div>
		</SearchContainer>
	</div>
</template>

<style lang="scss">
	.order-history-panel {
		.n-search-grid .tile {
			margin-bottom: 0.5rem;
			padding: 0.5rem;
		}

		.n-search-grid .tile:nth-child(odd) {
			background-color: #efefef;
		}
	}
</style>
