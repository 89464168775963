
// ShipToUserForm
// Implements the form that lets a user pick options for shipping to themselves.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { store } from '@/Store';


export default class UpdateAccountInfoForm extends Form {

    // Constructor
    constructor() {
        // Pass our model to the base class
        super(
            {
                userName: '',
                email: '',
                phoneNumber: ''
            });

        // Set required fields
        this.validator.fields.userName.isRequired = true;
        this.validator.fields.email.isRequired = true;
        this.validator.fields.phoneNumber.isRequired = true;
        this.validator.fields.phoneNumber.minLength = 14;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();

    }

    phoneNumberId = ''; // store phone number id to use it in updating phone number

    async init() {
        this.model.userName = store.user.userName;
        this.model.email = store.user.email;
    }

    onValidate() {
        super.onValidate();

        //this.validator.fields.accountType.checkNotEmpty();
    }

    async onSubmit() {
        store.user.userName = this.model.userName;
        store.user.email = this.model.email;
        await store.account.updateInfo(this.model.userName, this.model.email);
    }

    async getPhoneNumber() {
        const res = await store.userInfo.getPhoneNumber();
        if (res.succeeded) {
            this.model.phoneNumber = res.model.phoneNumber;
            this.phoneNumberId = res.model.id
        }
        else store.toast.error('Failed to get Phone Number!!');
    }

    async savePhoneNumber() {
        const res = await store.userInfo.updatePhoneNumber(this.model.phoneNumber, this.phoneNumberId);
        if (res.succeeded) store.toast.success('Successfully updated Phone Number!!');
        else store.toast.error('Failed to update Phone Number!!');
    }
}